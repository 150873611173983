import { useRef, useState } from 'react';
import cln from 'classnames';

import { ReactComponent as FileSvg } from 'assets/csa-icons/base/file.svg';

import ButtonBase from 'components/ui/Buttons/BaseButton';
import { api } from 'services/api';
import { InputDefaultState } from 'components/ui/Inputs/BaseInput/types';
import { Scheme } from 'utils/scheme';
import { useZustandNotificationStore } from 'stores/notification/store';
import { addNotificationAction } from 'stores/notification/selectors';
import { ENotificationType } from 'stores/notification/types';

import styles from './AwsS3FileUpload.module.css';

type Props = {
  field: Scheme & InputDefaultState;
  classes: string;
  onChange: (path: InputDefaultState) => void;
};

const AwsS3FileUpload = ({ classes, onChange, field }: Props) => {
  const [imgUrl, setImgUrl] = useState(
    `${
      field.value ? `${process.env.REACT_APP_BASE_IMAGE_URL}${field.value}` : ''
    }`
  );

  const inputRef = useRef<HTMLInputElement | null>(null);

  const [isLoading, setLoading] = useState(false);

  const addNotification = useZustandNotificationStore(addNotificationAction);

  const addFile = (e: any) => {
    const file = e.target.files[0];

    const url = URL.createObjectURL(file);

    setImgUrl(url);
  };

  const handleUploadImg = async () => {
    if (!inputRef.current || !field.awsPath) {
      return;
    }
    const { files = [] } = inputRef.current;

    const file = files?.[0];

    if (!file) {
      return;
    }

    const data = new FormData();

    data.append('file', file);
    data.append('pathname', `avatars/${new Date().getTime()}`);

    setLoading(true);
    const response = await api.post(`/s3/upload/${field.awsPath}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      timeout: 0,
    });

    setLoading(false);

    if (!response) {
      addNotification({
        type: ENotificationType.Error,
        text: `${file.name} is not uploaded`,
        uid: 'upload',
        autoClose: true,
      });

      return;
    }

    addNotification({
      type: ENotificationType.Success,
      text: `${file.name} is  uploaded`,
      uid: 'upload',
      autoClose: true,
    });

    // PASS URL
    onChange({ value: `/${field.awsPath}/${file.name}`, errorText: '' });

    setImgUrl('');
  };

  return (
    <>
      <div className={cln(styles.wrapperAwsS3FileUpload, classes)}>
        <input
          type="file"
          className={styles.fileInput}
          onChange={addFile}
          ref={inputRef}
        />

        {imgUrl ? (
          <img src={imgUrl} className={styles.img} />
        ) : (
          <FileSvg className={styles.icon} />
        )}
      </div>

      {imgUrl && (
        <ButtonBase
          color="blue"
          centreText
          buttonHeight="--button-height-l"
          size="--button-full"
          text={isLoading ? 'Uploading ...' : 'UPLOAD'}
          handleClick={handleUploadImg}
          classes={styles.btn}
        />
      )}
    </>
  );
};

export default AwsS3FileUpload;
