import React, { useState, useEffect, useMemo } from 'react';
import cln from 'classnames';

import ButtonBase from 'components/ui/Buttons/BaseButton';
import { PAGINATION_RANGE } from '../Table/constants';
import { PAGES } from './constants';

import styles from './Pagination.module.css';

type Props = {
  items: number;
  currentPage: number;
  handleNextPage: () => void;
  handlePrevPage: () => void;
  runToPage: (value: number) => () => void;
};

const Pagination = (props: Props) => {
  const { items, currentPage, handleNextPage, handlePrevPage, runToPage } =
    props;

  const [startPage, setStartPage] = useState(1);

  const endPage = useMemo(() => Math.ceil(items / PAGINATION_RANGE), [items]);

  let lastPage = endPage > PAGES ? PAGES : endPage;

  if (currentPage >= PAGES - 1) {
    lastPage = currentPage + 2;

    if (lastPage > endPage) {
      lastPage = endPage;
    }
  }

  useEffect(() => {
    const updatedStartPage = currentPage > 3 ? currentPage - 1 : 1;

    setStartPage(updatedStartPage);
  }, [currentPage]);

  const pages = new Array(lastPage - startPage + 1)
    .fill(0)
    .map((_, idx) => startPage + idx);

  return (
    <div className={styles.wrapperPagination}>
      <ButtonBase
        classes={styles.step}
        handleClick={handlePrevPage}
        text="Previous"
        size="--button-l"
        buttonHeight="--button-height-m"
        color="transparent"
        centreText
        disabled={!currentPage}
      />

      {startPage > 2 && (
        <>
          <div className={styles.page} onClick={runToPage(1)}>
            1
          </div>
          <div>...</div>
        </>
      )}

      {pages.map((page) => (
        <div
          key={page}
          className={cln(styles.page, {
            [styles.active]: page === currentPage + 1,
          })}
          onClick={runToPage(page)}
        >
          {page}
        </div>
      ))}

      {lastPage < endPage && (
        <>
          <div>...</div>
          <div className={styles.page} onClick={runToPage(endPage)}>
            {endPage}
          </div>
        </>
      )}

      <ButtonBase
        classes={styles.step}
        handleClick={handleNextPage}
        text="Next"
        size="--button-m"
        buttonHeight="--button-height-m"
        color="transparent"
        centreText
        disabled={currentPage + 1 === endPage}
      />
    </div>
  );
};

export default Pagination;
