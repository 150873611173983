import { useRef } from 'react';

import useOnClickOutside from 'hooks/useOutsideClick';
import { EEventType, TOutsideAlerterProps } from './types';

export default function HOCOutsideClick(props: TOutsideAlerterProps) {
  const { classes, children, eventType = EEventType.Click } = props;

  const wrapperRef = useRef(null);

  useOnClickOutside(wrapperRef, props.handleFunc, eventType);

  return (
    <div className={classes} ref={wrapperRef}>
      {children}
    </div>
  );
}
