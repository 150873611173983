import { useState } from 'react';

import { CreateFormProps } from 'components/layouts/CreateDeleteLayout/types';
import PopUp from 'components/layouts/PopUp';

import { addNotificationAction } from 'stores/notification/selectors';
import { useZustandNotificationStore } from 'stores/notification/store';

import { ETypeOfCompanies, ETypeOfPlatform } from './types';
import Form from 'components/Forms/Form';
import { CREATE_USER_SCHEME } from './scheme';
import { ReturnAdaptedType, getBody } from 'utils/scheme';
import { api } from 'services/api';
import { useZustandUsersStore } from 'stores/users/store';
import { updateUserAction } from 'stores/users/selectors';
import { User } from 'stores/users/types';
import { ENotificationType } from 'stores/notification/types';

const CreateUserForm = ({ onClose }: CreateFormProps) => {
  const addUser = useZustandUsersStore(updateUserAction);

  const [fields, setFields] = useState<ReturnAdaptedType>({});

  const addNotify = useZustandNotificationStore(addNotificationAction);

  const createUser = async () => {
    const body = getBody(fields);

    const response = await api.post<undefined, { data: { savedUser: User } }>(
      '/auth/registration',
      body
    );

    if (response) {
      const { data } = response;

      addNotify({
        type: ENotificationType.Success,
        text: 'user is  created',
        uid: 'user',
        autoClose: true,
      });
      addUser(data.savedUser.id, data.savedUser);
      onClose();

      return;
    }
    addNotify({
      type: ENotificationType.Error,
      text: 'user is not  created',
      uid: 'user',
      autoClose: true,
    });
  };

  return (
    <PopUp title="Create User" onClose={onClose} handleSubmit={createUser}>
      <Form scheme={CREATE_USER_SCHEME} callback={setFields} />
    </PopUp>
  );
};

export default CreateUserForm;
