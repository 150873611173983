import { Outlet } from 'react-router-dom';
import { useZustandAuthStore } from 'stores/userInfo/auth/store';
import { isAuthUserSelector } from 'stores/userInfo/auth/selectors';
import SignIn from 'pages/SignIn';
import { useEffect } from 'react';
import { ELocalStorage, getFromLocalStorage } from 'utils/localStorage';
import { api } from 'services/api';

const HOCProtectedRoute = () => {
  const isAuthorized = useZustandAuthStore(isAuthUserSelector);

  const autoSignIn = async () => {
    const token = getFromLocalStorage(ELocalStorage.AccessToken);

    if (token) {
      api.defaults.headers['Authorization'] = `Bearer ${token}`;
      // TODO ADD TO CONSTANT PATHNAME
      await api.get('/auth/token');
    }
  };

  useEffect(() => {
    autoSignIn();
  }, []);

  if (!isAuthorized) {
    return <SignIn />;
  }

  return <Outlet />;
};

export default HOCProtectedRoute;
