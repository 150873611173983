import ReactDOM from 'react-dom';

import NotificationView from './components/NotificationView';

import { getNotificationsSelector } from 'stores/notification/selectors';
import { useZustandNotificationStore } from 'stores/notification/store';

import styles from './Notification.module.css';

const Notification = () => {
  const notifications = useZustandNotificationStore(getNotificationsSelector);

  if (!notifications.length) {
    return null;
  }

  return ReactDOM.createPortal(
    <div className={styles.notificationBox}>
      {notifications.map((n) => (
        <NotificationView {...n} key={`${n.text}_${n.type}_${n.uid}`} />
      ))}
    </div>,
    document.body
  );
};

export default Notification;
