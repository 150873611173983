import cln from 'classnames';

import { RowInfo } from '../../utils';
import TableRow from './components/TableRow';
import Checkbox from 'components/ui/Checkbox';

import { useZustandFormStore } from 'stores/form/store';
import {
  getFormModeSelector,
  getRemoveIdsSelector,
  setRemoveIdStateAction,
} from 'stores/form/selectors';
import { EFormMode } from 'stores/form/types';

import styles from './TableBody.module.css';
import { useCallback } from 'react';

type Props = {
  rows: RowInfo[];
  handleEditAction?: (d: RowInfo) => () => void;
};

const TableBody = ({ rows, handleEditAction }: Props) => {
  const formState = useZustandFormStore(getFormModeSelector);

  const setRemoveIdState = useZustandFormStore(setRemoveIdStateAction);

  const removeIds = useZustandFormStore(getRemoveIdsSelector);

  const toggleState = useCallback(
    (id: string | number) => (s: boolean) => {
      setRemoveIdState(id, s);
    },
    [setRemoveIdState]
  );

  return (
    <div>
      {rows.map((r) => (
        <div
          key={r.id}
          className={cln(styles.row, {
            [styles.padding]:
              handleEditAction || formState === EFormMode.Delete,
            [styles.removed]: r.data?.isDelete,
          })}
        >
          <div className={styles.tableRow}>
            <TableRow {...r} />
          </div>

          {formState !== EFormMode.Delete && handleEditAction && (
            <div className={styles.editAction} onClick={handleEditAction(r)}>
              <img src="/csa-icons/base/settings.svg" />
            </div>
          )}
          {formState === EFormMode.Delete && (
            <div className={styles.editAction}>
              <Checkbox
                isActive={removeIds[r.id]}
                onChange={toggleState(r.id)}
              />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default TableBody;
