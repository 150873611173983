import { Scheme } from 'utils/scheme';

export const EDIT_USER_SCHEME: Scheme[] = [
  {
    name: 'fullName',
    validationFn: null,
    isRequired: true,
    requestFieldName: 'fullName',
  },
  {
    // REMOVE THIS FIELD
    name: '1c user id',
    validationFn: null,
    isRequired: true,
    requestFieldName: 'cmsId',
  },
  {
    name: 'new password',
    validationFn: null,
    isRequired: true,
    requestFieldName: 'newPassword',
  },
  {
    name: 'new password',
    validationFn: null,
    isRequired: true,
    requestFieldName: 'newPassword',
  },
];
