import { useState } from 'react';

import { InputDefaultState } from '../../../ui/Inputs/BaseInput/types';
import PopUp from 'components/layouts/PopUp';

import { CREATE_CATEGORY_SCHEME } from './scheme';

import { adaptedSchemeToState, getBody } from 'utils/scheme';

import { api } from 'services/api';

import { CreateFormProps } from 'components/layouts/CreateDeleteLayout/types';
import { useZustandNotificationStore } from 'stores/notification/store';
import { addNotificationAction } from 'stores/notification/selectors';
import { ENotificationType } from 'stores/notification/types';
import Form from 'components/Forms/Form';
import { useZustandDocsStore } from 'stores/docs/store';
import { updateDocAction } from 'stores/docs/selectors';
import { useUsers } from 'hooks/userHooks/useUsers';
import Select from 'components/ui/Select';
import Selector from 'components/ui/Selector';
import { forEachChild } from 'typescript';

const CreateDocumentForm = ({ onClose }: CreateFormProps) => {
  const [fields, setFields] = useState(() =>
    adaptedSchemeToState(CREATE_CATEGORY_SCHEME)
  );
  const addNotification = useZustandNotificationStore(addNotificationAction);

  const { data: users } = useUsers();

  const updateDoc = useZustandDocsStore(updateDocAction);

  const [selectedUserIds, setUserIds] = useState<number[]>([]);

  const saveDocument = async () => {
    const body = getBody(fields);

    const mainFile = document.getElementById('file-main') as HTMLInputElement;
    const attachments = document.getElementById(
      'file-attachments'
    ) as HTMLInputElement;

    if (!mainFile || !selectedUserIds.length) {
      addNotification({
        type: ENotificationType.Error,
        text: 'invalid data in form',
        uid: 'form error',
        autoClose: true,
      });

      return;
    }

    const sendData = new FormData();

    // @ts-ignore
    sendData.append('file', mainFile.files?.[0]);

    // selectedUserIds.forEach((f) => {
    //   sendData.append('userIds', `${f}`);
    // });

    sendData.append('userIds', JSON.stringify(selectedUserIds));
    // @ts-ignore
    // sendData.append('userIds', [3, 4, 5, 7]);

    // @ts-ignore
    if (attachments.files?.length > 0) {
      // @ts-ignore
      for (let i = 0; i < attachments.files.length; i++) {
        // @ts-ignore
        sendData.append('attachments', attachments.files[i]);
      }
    }

    Object.keys(body).forEach((k) => {
      sendData.append(k, body[k]);
    });

    const response = await api.post('/docs/upload', sendData, {
      timeout: 0,
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    if (!response) {
      addNotification({
        type: ENotificationType.Error,
        text: 'Doc has not been created',
        uid: 'd-created',
        autoClose: true,
      });

      return;
    }

    const { data } = response;

    addNotification({
      type: ENotificationType.Success,
      text: 'doc created',
      uid: 'd-created',
      autoClose: true,
    });

    updateDoc(data.id, data);

    onClose();
  };

  const handleChooseUser = (id: number) => {
    const newV: { [id: number]: boolean } = {};
    let isRemove = false;

    selectedUserIds.forEach((sId) => {
      if (sId === id) {
        isRemove = true;

        return true;
      }
      newV[sId] = true;
    });

    if (!isRemove) {
      newV[id] = true;
    }

    setUserIds(Object.keys(newV).map((id) => +id));
  };

  return (
    <PopUp title="Document" onClose={onClose} handleSubmit={saveDocument}>
      <>
        <Form scheme={CREATE_CATEGORY_SCHEME} callback={setFields} />

        <div style={{ marginBottom: 'var(--indent-base)' }}>
          {/* TODO: move to form */}
          <input type="file" onChange={console.info} id="file-main" />

          {/* ATTACHMENTS */}
          <input
            type="file"
            onChange={console.info}
            id="file-attachments"
            multiple
          />
        </div>
        <Selector
          isOpenUp
          isMulti
          placeholder="all"
          activeId={null}
          activeIds={selectedUserIds}
          joinSymbol=","
          handleClick={handleChooseUser}
          data={users.map((u) => ({ id: u.id, name: u.email }))}
        />
      </>
    </PopUp>
  );
};

export default CreateDocumentForm;
