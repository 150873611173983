import useSWR from 'swr';
import { BASE_URL } from 'constants/api';
import { useEffect } from 'react';
import { api } from 'services/api';

import { getDocsSelector, setDocsAction } from 'stores/docs/selectors';
import { useZustandDocsStore } from 'stores/docs/store';
import { Doc } from 'stores/docs/types';

const fetcher = (url: string) =>
  api.get<undefined, { data: Doc[] }>(url).then((res) => res.data);

export const useDocuments = () => {
  const docs = useZustandDocsStore(getDocsSelector);

  const setDocs = useZustandDocsStore(setDocsAction);

  const { data, error, isLoading } = useSWR(
    Object.keys(docs).length ? null : `${BASE_URL}/docs`,
    fetcher
  );

  useEffect(() => {
    if (data) {
      setDocs(data);
    }
  }, [data]);

  return { data: Object.values(docs), error, isLoading };
};
