import CreateDocumentForm from 'components/Forms/Docs/CreateDocumentForm';
import EditDocInfoForm from 'components/Forms/Docs/EditDocInfoForm';
import CreateDeleteLayout from 'components/layouts/CreateDeleteLayout';
import TableUi from 'components/ui/Table';
import { prepareDataForTable } from 'components/ui/Table/utils';
import { useDocuments } from 'hooks/docHooks/useDocs';
import React, { useMemo } from 'react';
import { api } from 'services/api';
import { setDocsAction } from 'stores/docs/selectors';
import { useZustandDocsStore } from 'stores/docs/store';
import { Doc } from 'stores/docs/types';
import { getRemoveIdsSelector } from 'stores/form/selectors';
import { useZustandFormStore } from 'stores/form/store';

const tableSettings = {
  columns: ['id', 'amount', 'currencyName', 'description', 'createAt', 'cmsId'],
  size: [10, 10, 10, 30, 30, 10],
};

const Documents = () => {
  const { data: docs } = useDocuments();

  const removeIds = useZustandFormStore(getRemoveIdsSelector);

  const setDocs = useZustandDocsStore(setDocsAction);

  const preparedData = useMemo(() => {
    const baseValue = docs
      ? prepareDataForTable(Object.values(docs), tableSettings)
      : [];

    return baseValue;
  }, [docs]);

  const handleDelete = async () => {
    try {
      const { data: responseData } = await api.post('/docs/delete', {
        ids: removeIds,
      });

      setDocs(responseData);
    } catch (error) {
      console.log('🚀 ~ handleDelete ~ error:', error);
    }
  };

  return (
    <CreateDeleteLayout
      name="Docs"
      CreateForm={CreateDocumentForm}
      handleDeleteFn={handleDelete}
    >
      <TableUi rows={preparedData} EditForm={EditDocInfoForm} />
    </CreateDeleteLayout>
  );
};

export default Documents;
