import { Scheme } from 'utils/scheme';

export const EDIT_DOCUMENT_SCHEME: Scheme[] = [
  {
    name: 'amount',
    validationFn: null,
    isRequired: true,
    convertType: 'number',
    requestFieldName: 'amount',
  },
  {
    name: 'currency name',
    validationFn: null,
    isRequired: true,
    requestFieldName: 'currencyName',
  },
  {
    name: 'senderId',
    validationFn: null,
    isRequired: true,
    convertType: 'number',
    requestFieldName: 'senderId',
  },
  {
    name: 'receiverId',
    validationFn: null,
    isRequired: true,
    convertType: 'number',
    requestFieldName: 'receiverId',
  },
];
