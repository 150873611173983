import { create } from 'zustand';
import { ZustandUsersStore } from './types';

export const useZustandUsersStore = create<ZustandUsersStore>((set, get) => ({
  users: {},

  setUsers: (users) => {
    set({ users });
  },

  updateUser: (id, u) => {
    const { users } = get();
    const newUsers = {
      ...users,
      [id]: u,
    };

    set({ users: newUsers });
  },
}));
