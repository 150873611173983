import cln from 'classnames';

import { useZustandFormStore } from 'stores/form/store';
import { getFormModeSelector } from 'stores/form/selectors';
import { EFormMode } from 'stores/form/types';

import styles from './TableHeader.module.css';

type HeaderItem = {
  name: string;
  id: number;
  size: number;
};

type Props = {
  data: HeaderItem[];
  isEditedAction?: boolean;
};

const TableHeader = (props: Props) => {
  const { data, isEditedAction } = props;

  const formState = useZustandFormStore(getFormModeSelector);

  return (
    <div
      className={cln(styles.wrapperTableHeader, {
        [styles.editWith]: isEditedAction || formState === EFormMode.Delete,
      })}
    >
      {data.map((d) => (
        <div
          className={styles.headerItem}
          key={d.id}
          style={{
            width: `${d.size}%`,
          }}
        >
          {d.name}
        </div>
      ))}
    </div>
  );
};

export default TableHeader;
