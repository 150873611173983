import axios from 'axios';
import { BASE_URL } from 'constants/api';
import { useZustandAuthStore } from 'stores/userInfo/auth/store';
import { ELocalStorage, setToLocalStorage } from 'utils/localStorage';

export const api = axios.create({
  baseURL: BASE_URL,
  timeout: 30000,
});

api.interceptors.response.use(
  function (response) {
    if (
      response.request.responseURL === `${BASE_URL}/auth/authorization` ||
      response.request.responseURL === `${BASE_URL}/auth/token`
    ) {
      const { data } = response;
      const { tokens } = data;

      api.defaults.headers['Authorization'] = `Bearer ${tokens.accessToken}`;
      setToLocalStorage(ELocalStorage.AccessToken, tokens.accessToken);

      useZustandAuthStore.getState().setAuthState(true);
    }

    return response;
  },
  function (error) {
    console.error('ERROR SHOW NOTIFICATION MSG', error.response);

    return null;
  }
);
