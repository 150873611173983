import { ZustandDocsStore } from './types';

export const getDocsSelector = (store: ZustandDocsStore) => store.docs;

export const getDocByIdSelector = (id: number) => (store: ZustandDocsStore) =>
  getDocsSelector(store)[id];

// ACTIONS
export const setDocsAction = (store: ZustandDocsStore) => store.setDocs;

export const updateDocAction = (store: ZustandDocsStore) => store.updateDoc;
