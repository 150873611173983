import React, { useMemo, useState } from 'react';

import CreateDeleteLayout from 'components/layouts/CreateDeleteLayout';
import { prepareDataForTable } from 'components/ui/Table/utils';
import TableUi from 'components/ui/Table';

import EditUserInfoForm from 'components/Forms/Users/EditUserInfoForm';
import { useUsers } from 'hooks/userHooks/useUsers';
import CreateUserForm from 'components/Forms/Users/CreateUser';

const tableSettings = {
  columns: ['id', 'email', 'fullName', 'cmsId'],
  size: [10, 40, 40, 10],
};

const Users = () => {
  const { data: users } = useUsers();

  const preparedData = useMemo(() => {
    const baseValue = users
      ? prepareDataForTable(Object.values(users), tableSettings)
      : [];

    return baseValue;
  }, [users]);

  return (
    <CreateDeleteLayout name="Users" CreateForm={CreateUserForm}>
      <TableUi rows={preparedData} EditForm={EditUserInfoForm} />
    </CreateDeleteLayout>
  );
};

export default Users;
