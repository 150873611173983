import { Outlet } from 'react-router';

import Drawer from 'components/Drawer';

import styles from './DrawerLayout.module.css';

const DrawerLayout = () => (
  <div className={styles.content}>
    <div
      className={styles.wrapperDrawerLayout}
      style={{ width: window.innerHeight }}
    >
      <Drawer />
    </div>

    <div className={styles.adminContent}>
      <Outlet />
    </div>
  </div>
);

export default DrawerLayout;
