import { ZustandUsersStore } from './types';

export const getUsersSelector = (store: ZustandUsersStore) => store.users;

export const getUserByIdSelector = (id: number) => (store: ZustandUsersStore) =>
  getUsersSelector(store)[id];

// ACTIONS
export const setUsersAction = (store: ZustandUsersStore) => store.setUsers;

export const updateUserAction = (store: ZustandUsersStore) => store.updateUser;
