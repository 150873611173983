import { Scheme } from 'utils/scheme';

export const CREATE_USER_SCHEME: Scheme[] = [
  {
    name: 'email',
    validationFn: null,
    isRequired: true,
    requestFieldName: 'email',
  },
  {
    name: 'password',
    validationFn: null,
    isRequired: true,
    requestFieldName: 'password',
  },
  {
    name: 'cmsId',
    validationFn: null,
    isRequired: true,
    requestFieldName: 'cmsId',
  },
  {
    name: 'fullName',
    validationFn: null,
    isRequired: true,
    requestFieldName: 'fullName',
  },
];
