export enum EEventType {
  Click = 'click',
  Mousedown = 'mousedown',
}

export type TOutsideAlerterProps = {
  children: React.ReactNode;
  classes?: string;

  handleFunc: (event: any) => void;
  //
  // handleFunc: (event: Event) => void;
  eventType?: EEventType;
};
