import { InputDefaultState } from 'components/ui/Inputs/BaseInput/types';
import { prepareDate } from './date';

export type Scheme = {
  name: string;
  isRequired: boolean;
  validationFn: null | ((d: InputDefaultState) => InputDefaultState);
  requestFieldName: string;
  convertType?: 'number' | 'boolean' | 'array';
  isCheckbox?: boolean;
  isCalendar?: boolean;
  isAwsFile?: boolean;
} & (
  | {
      isAwsFile: true;
      awsPath: string;
    }
  | { isAwsFile?: false | undefined; awsPath?: string }
);

export type ReturnAdaptedType = { [name: string]: Scheme & InputDefaultState };

export const adaptedSchemeToState = (scheme: Scheme[]): ReturnAdaptedType => {
  const fields: ReturnAdaptedType = {};

  scheme.forEach((f) => {
    fields[f.name] = { ...f, value: '', errorText: '' };
  });

  return fields;
};

export type ReturnBodyType = { [requestName: string]: any };

export const getBody = (d: ReturnAdaptedType): ReturnBodyType => {
  const body: ReturnBodyType = {};

  Object.values(d).forEach((f) => {
    if (f.convertType === 'number') {
      body[f.requestFieldName] = +f.value;

      return true;
    }

    if (f.convertType === 'boolean') {
      body[f.requestFieldName] = Boolean(f.value);

      return true;
    }

    if (f.convertType === 'array') {
      const items = f.value.toString().split(',');

      body[f.requestFieldName] = items;

      return true;
    }

    if (f.isAwsFile) {
      body[f.requestFieldName] = f.value;

      return true;
    }

    body[f.requestFieldName] = f.value;
  });

  return body;
};
