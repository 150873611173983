export const prepareDate = (timestamp: number) =>
  new Date(timestamp).toISOString().replace('T', ' ');

export const isToday = (date: Date | number | string) => {
  const today = new Date();
  const givenDate = new Date(date);

  return (
    givenDate.getDate() === today.getDate() &&
    givenDate.getMonth() === today.getMonth() &&
    givenDate.getFullYear() === today.getFullYear()
  );
};
