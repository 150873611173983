export interface ZustandFormStore extends ZustandFormEntities {
  setMode: (
    m: EFormMode,
    initial?: { [key: string | number]: boolean }
  ) => void;
  setRemoveIdState: (id: number | string, state: boolean) => void;
}

interface ZustandFormEntities {
  mode: EFormMode;
  removeIds: { [key: string | number]: boolean };
}

export const enum EFormMode {
  Idle,
  Delete,
}
