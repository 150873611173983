import { Navigate, Route, Routes } from 'react-router-dom';

import DrawerLayout from 'components/layouts/DrawerLayout';
import HOCProtectedRoute from 'components/hocs/HOCProtectedRoute';

import { ERouteNames } from './types';
import Users from 'pages/Users';
import Documents from 'pages/Documents';

const MainRoute = () => (
  <Routes>
    <Route element={<HOCProtectedRoute />}>
      <Route element={<DrawerLayout />}>
        <Route path={ERouteNames.Users} element={<Users />} />

        <Route path={ERouteNames.Documents} element={<Documents />} />

        <Route path="*" element={<Navigate to={ERouteNames.Home} replace />} />
      </Route>
    </Route>
  </Routes>
);

export default MainRoute;
