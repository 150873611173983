import React, { useCallback, useEffect, useState } from 'react';
import { SIGN_IN_SCHEME } from './scheme';
import BaseInput from 'components/ui/Inputs/BaseInput';

import styles from './SignInForm.module.css';
import { InputDefaultState } from 'components/ui/Inputs/BaseInput/types';
import ButtonBase from 'components/ui/Buttons/BaseButton';
import { ReturnAdaptedType, adaptedSchemeToState } from 'utils/scheme';

type Props = {
  callback: (d: ReturnAdaptedType) => void;
};

const SignInForm = ({ callback }: Props) => {
  const [fields, setFields] = useState(() =>
    adaptedSchemeToState(SIGN_IN_SCHEME)
  );

  const setFieldInfo = (name: string) => (inputInfo: InputDefaultState) => {
    const newState = { ...fields };

    newState[name] = {
      ...newState[name],
      ...inputInfo,
    };

    setFields(newState);
  };

  const handleClick = () => {
    callback(fields);
  };

  return (
    <div className={styles.wrapperForm}>
      {Object.values(fields).map((field) => (
        <BaseInput
          size="--input-full"
          key={field.name}
          placeholder={field.name}
          onChange={setFieldInfo(field.name)}
          inputState={field}
          classes={styles.signInInput}
        />
      ))}

      <ButtonBase
        text="Submit"
        size="--button-xxl"
        color="red"
        handleClick={handleClick}
        buttonHeight="--button-height-l"
        centreText
      />
    </div>
  );
};

export default SignInForm;
