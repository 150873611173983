import { RowInfo } from 'components/ui/Table/utils';
import PopUp from 'components/layouts/PopUp';

import { EDIT_DOCUMENT_SCHEME } from './scheme';
import { useState } from 'react';
import { ReturnAdaptedType, getBody } from 'utils/scheme';
import Form from 'components/Forms/Form';
import { useZustandNotificationStore } from 'stores/notification/store';
import { addNotificationAction } from 'stores/notification/selectors';

import { useZustandDocsStore } from 'stores/docs/store';
import { getDocByIdSelector, updateDocAction } from 'stores/docs/selectors';

type Props = {
  info: RowInfo;
  //  & { isHome: 'true' | 'false' };
  onClose: () => void;
};

const EditDocInfoForm = ({ info, onClose }: Props) => {
  const { columns, id } = info;

  const [fields, setFields] = useState<ReturnAdaptedType>({});

  const doc = useZustandDocsStore(getDocByIdSelector(+id));

  const updateDoc = useZustandDocsStore(updateDocAction);

  const addNotification = useZustandNotificationStore(addNotificationAction);

  const handleUpdateDoc = async () => {
    const body = getBody(fields);

    console.log('🚀 ~ handleUpdateDoc ~ body:', body);

    // const response = await api.post('/users/update', { ...body, id: user.id });

    // if (!response) {
    //   addNotification({
    //     type: ENotificationType.Error,
    //     text: 'is not updated',
    //     uid: 'update-category',
    //     autoClose: true,
    //   });

    //   return;
    // }
    // // const updateUserData = {
    // //   ...user,
    // //   ...body,
    // // };

    // addNotification({
    //   type: ENotificationType.Success,
    //   text: 'is updated',
    //   uid: 'update-category',
    //   autoClose: true,
    // });

    // // updateUser(user.id, updateUserData as User);
    // onClose();
  };

  if (!doc) {
    return <div>doc not found error</div>;
  }

  return (
    <PopUp
      title="Update doc Info"
      onClose={onClose}
      handleSubmit={handleUpdateDoc}
      isValidForm={Boolean(Object.keys(fields).length)}
    >
      <Form
        scheme={EDIT_DOCUMENT_SCHEME}
        callback={setFields}
        defaultValues={info}
      />
    </PopUp>
  );
};

export default EditDocInfoForm;
