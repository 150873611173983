import { Scheme } from 'utils/scheme';

export const CREATE_CATEGORY_SCHEME: Scheme[] = [
  {
    name: 'amount',
    validationFn: null,
    isRequired: true,
    convertType: 'number',
    requestFieldName: 'amount',
  },
  {
    name: 'currency name',
    validationFn: null,
    isRequired: true,
    requestFieldName: 'currency',
  },
  {
    name: 'cms Id',
    validationFn: null,
    isRequired: true,
    requestFieldName: 'cmsId',
  },
  {
    name: 'contractor',
    validationFn: null,
    isRequired: true,
    requestFieldName: 'contractor',
  },
  {
    name: 'contractorAgreement',
    validationFn: null,
    isRequired: true,
    requestFieldName: 'contractorAgreement',
  },

  {
    name: 'contractorBankAccount',
    validationFn: null,
    isRequired: true,
    requestFieldName: 'contractorBankAccount',
  },
  {
    name: 'contractorBankName',
    validationFn: null,
    isRequired: true,
    requestFieldName: 'contractorBankName',
  },
  {
    name: 'paymentPurpose',
    validationFn: null,
    isRequired: true,
    requestFieldName: 'paymentPurpose',
  },
  {
    name: 'paymentDetails',
    validationFn: null,
    isRequired: true,
    requestFieldName: 'paymentDetails',
  },
  {
    name: 'organisation',
    validationFn: null,
    isRequired: true,
    requestFieldName: 'organisation',
  },
  {
    name: 'productCode',
    validationFn: null,
    isRequired: true,
    requestFieldName: 'productCode',
  },
  {
    name: 'orderStatus',
    validationFn: null,
    isRequired: true,
    requestFieldName: 'orderStatus',
  },

  {
    name: 'accountType',
    validationFn: null,
    isRequired: true,
    requestFieldName: 'accountType',
  },
  {
    name: 'date',
    validationFn: null,
    isRequired: true,
    requestFieldName: 'date',
  },

  // File and attachments
];
