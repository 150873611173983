import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import BaseButton from 'components/ui/Buttons/BaseButton';
import { CreateDeleteLayoutProps } from './types';
import { useZustandFormStore } from 'stores/form/store';
import { getFormModeSelector, setFormModeAction } from 'stores/form/selectors';

import styles from './CreateDeleteLayout.module.css';

import { EFormMode } from 'stores/form/types';

const CreateDeleteLayout = (props: CreateDeleteLayoutProps) => {
  const {
    handleCreate,
    name,
    children,
    handleDeleteFn,
    CreateForm,
    removeData,
  } = props;

  const [isPopUp, setPopUpState] = useState(false);

  const setFormMode = useZustandFormStore(setFormModeAction);

  const formState = useZustandFormStore(getFormModeSelector);

  const handleDeleteMode = () => {
    if (formState === EFormMode.Delete) {
      handleDeleteFn?.();

      setFormMode(EFormMode.Idle);

      return;
    }

    setFormMode(EFormMode.Delete, removeData);
  };

  const showLocalForm = () => {
    setPopUpState(!isPopUp);
  };

  useEffect(
    () => () => {
      setFormMode(EFormMode.Idle);
    },
    []
  );

  return (
    <div className={styles.wrapperCreateDeleteLayout}>
      <div className={styles.titleWrapper}>
        <div className={styles.name}>{name}</div>

        <div className={styles.buttonsWrapper}>
          <BaseButton
            handleClick={CreateForm ? showLocalForm : handleCreate}
            text="Create"
            color="green"
            buttonHeight="--button-height-xl"
            size="--button-xl"
            centreText
          />

          {handleDeleteFn && (
            <BaseButton
              text={
                formState === EFormMode.Delete ? 'remove selected' : 'Delete'
              }
              handleClick={handleDeleteMode}
              marginL="base-double"
              color="red"
              buttonHeight="--button-height-xl"
              size="--button-xl"
              centreText
            />
          )}
        </div>
      </div>

      {children}

      {isPopUp &&
        createPortal(
          <div className={styles.popUp}>
            {CreateForm && <CreateForm onClose={showLocalForm} />}
          </div>,
          document.body
        )}
    </div>
  );
};

export default CreateDeleteLayout;
