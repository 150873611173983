export const enum ELocalStorage {
  RefreshToken = 'rf',
  AccessToken = 'ac',
}

export const setToLocalStorage = (
  key: ELocalStorage,
  data: { [key: string]: string } | string
) => {
  localStorage.setItem(key, JSON.stringify(data));
};

export const removeFromLocalStorage = (key: ELocalStorage) => {
  localStorage.removeItem(key);
};

export const getFromLocalStorage = (key: ELocalStorage) => {
  const value = localStorage.getItem(key);

  if (!value) {
    return null;
  }

  return JSON.parse(value);
};
