import { NavLink } from 'react-router-dom';
import cln from 'classnames';
import { ReactComponent as ShapeSVG } from 'assets/csa-icons/drawer/shape.svg';

import styles from './ItemRow.module.css';

type Props = {
  name: string;
  leftPathName?: React.ReactElement;
  pathname: string;
};

const ItemRow = (props: Props) => {
  const { name, leftPathName, pathname } = props;

  return (
    <NavLink
      to={pathname}
      className={({ isActive }) =>
        cln(styles.itemRow, {
          [styles.active]: isActive,
        })
      }
    >
      <div className={styles.leftSection}>
        <div className={styles.icon}>{leftPathName}</div>

        <div className={styles.name}>{name}</div>
      </div>

      <ShapeSVG className={styles.shape} />
    </NavLink>
  );
};

export default ItemRow;
