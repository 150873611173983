type InitialData = { id: number | string; [key: string]: any };

type Settings = {
  columns: string[];
  size: number[];
};

export type RowInfo = {
  [value: string]: any;

  id: number | string;
  size: { [columnName: string]: number };
  columns: string[];
};
// {
//   columns: ['id', 'name', 'isActive', 'isHome'];
//   size: [10, 30, 30, 30];
// };

export const prepareDataForTable = (
  data: InitialData[],
  settings: Settings
): RowInfo[] => {
  const tableRows: RowInfo[] = [];

  data.forEach((d: InitialData) => {
    const row: RowInfo = { id: -1, value: '', size: {}, columns: [], data: d };

    settings.columns.forEach((c: keyof InitialData, inx) => {
      const value = d?.[c] || 'Unknown';
      const v = c in d ? value.toString() : '-';
      const s = settings.size[inx] || 0;

      if (!(c in d)) {
        return true;
      }

      row.id = d.id;
      row[c] = v.toString();
      row.size[c] = s;
      row.columns = settings.columns;
    });

    if (row.id !== -1) {
      tableRows.push(row);
    }
  });

  return tableRows;
};

export const prepareHeaderTabs = (r: RowInfo) =>
  r.columns.map((name, inx) => ({
    name,
    id: inx,
    size: r.size[name],
  }));

export const prepareInitialRemoveIdsData = (data: RowInfo[]) => {
  const obj: { [key: number | string]: boolean } = {};

  data.forEach((d) => {
    const state = d.data?.isDelete;

    if (state) {
      obj[d.id] = state;
    }
  });

  return obj;
};
