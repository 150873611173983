import { create } from 'zustand';
import { EFormMode, ZustandFormStore } from './types';

export const useZustandFormStore = create<ZustandFormStore>((set, get) => ({
  mode: EFormMode.Idle,
  removeIds: {},

  setMode: (mode, removeIds = {}) => {
    set({ mode, removeIds });
  },

  setRemoveIdState: (id, state) => {
    const { removeIds } = get();

    set({ removeIds: { ...removeIds, [id]: state } });
  },
}));
