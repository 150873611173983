import { create } from 'zustand';
import { ZustandDocsStore } from './types';

export const useZustandDocsStore = create<ZustandDocsStore>((set, get) => ({
  docs: {},

  setDocs: (docs) => {
    set({ docs });
  },

  updateDoc: (id, d) => {
    const { docs } = get();
    const newDocs = {
      ...docs,
      [id]: d,
    };

    set({ docs: newDocs });
  },
}));
