import useSWR from 'swr';
import { BASE_URL } from 'constants/api';
import { useEffect, useState } from 'react';
import { api } from 'services/api';

import { useZustandUsersStore } from 'stores/users/store';
import { getUsersSelector, setUsersAction } from 'stores/users/selectors';
import { User } from 'stores/users/types';

const fetcher = (url: string) =>
  api.get<undefined, { data: User[] }>(url).then((res) => res.data);

export const useUsers = () => {
  const users = useZustandUsersStore(getUsersSelector);

  const setUsers = useZustandUsersStore(setUsersAction);

  const { data, error, isLoading } = useSWR(
    Object.keys(users).length ? null : `${BASE_URL}/users/all`,
    fetcher
  );

  useEffect(() => {
    if (data) {
      const users: { [id: number]: User } = {};

      data.forEach((u) => {
        // TODO: default value for TABLE  can be created automaticly
        users[u.id] = { ...u, cmsId: u.cmsId ? u.cmsId : 'Unknown' };
      });

      setUsers(users);
    }
  }, [data]);

  return { data: Object.values(users), error, isLoading };
};
