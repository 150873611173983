import cln from 'classnames';
import { RowInfo } from 'components/ui/Table/utils';

import styles from './TableRow.module.css';

const TableRow = (props: RowInfo) => {
  const { columns, data = {} } = props;

  return (
    <div
      className={cln(styles.rowWrapper, {
        [styles.ended]: 'isActive' in data && !data.isActive,
      })}
    >
      {columns.map((r) => (
        <div
          key={r}
          className={styles.rowItem}
          style={{
            width: `${props.size[r]}%`,
          }}
        >
          <div className={styles.name}>{props[r]}</div>
        </div>
      ))}
    </div>
  );
};

export default TableRow;
