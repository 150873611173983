import { ZustandFormStore } from './types';

export const getFormModeSelector = (store: ZustandFormStore) => store.mode;

export const getRemoveIdsSelector = (store: ZustandFormStore) =>
  store.removeIds;

export const isSelectedItemSelector =
  (id: number | string) => (store: ZustandFormStore) =>
    getRemoveIdsSelector(store)[id];

// Actions

export const setFormModeAction = (store: ZustandFormStore) => store.setMode;

export const setRemoveIdStateAction = (store: ZustandFormStore) =>
  store.setRemoveIdState;
