import { RowInfo } from 'components/ui/Table/utils';
import PopUp from 'components/layouts/PopUp';

import { api } from 'services/api';
import { EDIT_USER_SCHEME } from './scheme';
import { useEffect, useMemo, useState } from 'react';
import { ReturnAdaptedType, getBody } from 'utils/scheme';
import Form from 'components/Forms/Form';
import { useZustandNotificationStore } from 'stores/notification/store';
import { addNotificationAction } from 'stores/notification/selectors';
import { ENotificationType } from 'stores/notification/types';
import { useZustandUsersStore } from 'stores/users/store';
import { getUserByIdSelector, updateUserAction } from 'stores/users/selectors';
import { User } from 'stores/users/types';

type Props = {
  info: RowInfo;
  //  & { isHome: 'true' | 'false' };
  onClose: () => void;
};

const EditUserInfoForm = ({ info, onClose }: Props) => {
  const { columns, id } = info;

  const [fields, setFields] = useState<ReturnAdaptedType>({});

  const user = useZustandUsersStore(getUserByIdSelector(+id));

  const updateUser = useZustandUsersStore(updateUserAction);

  const addNotification = useZustandNotificationStore(addNotificationAction);

  const handleUpdateCategory = async () => {
    const body = getBody(fields);

    const response = await api.post('/users/update', { ...body, id: user.id });

    if (!response) {
      addNotification({
        type: ENotificationType.Error,
        text: 'is not updated',
        uid: 'update-category',
        autoClose: true,
      });

      return;
    }
    const updateUserData = {
      ...user,
      ...body,
    };

    addNotification({
      type: ENotificationType.Success,
      text: 'is updated',
      uid: 'update-category',
      autoClose: true,
    });

    updateUser(user.id, updateUserData as User);
    onClose();
  };

  const data = useMemo(() => {
    if (user.cmsId !== 'Unknown') {
      const copy = [...EDIT_USER_SCHEME];

      copy.splice(1, 1);

      return copy;
    }

    return EDIT_USER_SCHEME;
  }, [user]);

  if (!user) {
    return <div>user not found error</div>;
  }

  return (
    <PopUp
      title="Update User Info"
      onClose={onClose}
      handleSubmit={handleUpdateCategory}
      isValidForm={Boolean(Object.keys(fields).length)}
    >
      <Form scheme={data} callback={setFields} defaultValues={info} />
    </PopUp>
  );
};

export default EditUserInfoForm;
