import cln from 'classnames';
import { useEffect, useRef, useState } from 'react';

import { Props } from './types';

import styles from './Selector.module.css';
import HOCOutsideClick from 'components/hocs/HOCOutsideClick';

const Selector = (props: Props) => {
  const {
    placeholder = '',
    data,
    classes,
    activeId,
    isOpenUp,
    handleClick,
    isAll,
    classesItem,
    selectedMainItemClasses = '',
    isMulti,
    activeIds,
    joinSymbol = ',',
    selectorStyleType = 'base',
    type = 'base',
    additionalModalProps,
  } = props;

  const [isOpened, setOpenState] = useState<boolean>(false);

  const [isHideContent, setHideContentState] = useState<boolean>(true);

  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);

  const isDisabled = data.length < 2;

  const handleToggleState = (e: any) => {
    e.stopPropagation();

    if (type === 'button') {
      // @ts-ignore
      handleClick();

      return;
    }

    if (isHideContent && !isOpened) {
      setHideContentState(false);
    }
    setOpenState(!isOpened);
  };

  const resetValue = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    if (!isAll) {
      return;
    }

    setOpenState(false);

    handleClick(null);
  };

  const setAndClose = (id: number | null) => (e: any) => {
    e.stopPropagation();
    setOpenState(false);

    // TODO because of isAll change props types for function
    handleClick(id as number);
  };

  const getTitle = () => {
    if (isMulti) {
      const names: string[] = [];

      data.forEach((i) => {
        if (activeIds.includes(i.id)) {
          names.push(i.name);
        }
      });

      return names.join(joinSymbol);
    }

    return data.find((i) => i.id === activeId)?.name || placeholder;
  };

  useEffect(() => {
    if (timer.current) {
      clearTimeout(timer.current);
    }

    if (!isOpened) {
      timer.current = setTimeout(() => {
        setHideContentState(true);
      }, 500);
    }
  }, [isOpened]);

  useEffect(
    () => () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    },
    []
  );

  const directionModifier = `dropDownDirection-${isOpenUp ? 'up' : 'down'}`;

  // const sizeModifier = `dropDownContentSize-${size}`;
  const sizeModifier = 'dropDownContentSize-large';

  return (
    <div className={cln(styles.wrapperSelector, styles[sizeModifier], classes)}>
      <div
        className={cln(
          styles.mainContainer,
          styles.item,
          styles[sizeModifier],
          classesItem,

          {
            [styles.opacity]: isOpened,
            [selectedMainItemClasses]: activeId || activeIds?.length,
          }
        )}
        onClick={isDisabled && type === 'base' ? undefined : handleToggleState}
      >
        {selectorStyleType === 'labelUp' && placeholder && (
          <div className={styles.placeholder}>{placeholder}</div>
        )}

        <div className={styles.overflow}>{getTitle()}</div>

        <div className={styles.iconBlock}>I</div>
      </div>
      <div
        className={cln(
          styles.dropDownWrapper,
          styles[directionModifier],
          styles[sizeModifier],

          {
            [styles.animationSelectorGrowing]: isOpened,
            [styles.bigBorder]: isOpened,
            [styles.disabledBottom]: isOpenUp,
            [styles.disabledTop]: !isOpenUp,
            [styles.animationSelectorReducing]: !isOpened,
          }
        )}
      >
        {!isHideContent && isAll && (
          <div
            className={cln(styles.item, classesItem)}
            onClick={setAndClose(null)}
          >
            reset
          </div>
        )}
        {!isHideContent && (
          <HOCOutsideClick handleFunc={handleToggleState}>
            {data.map((i) => (
              <div
                className={cln(styles.item, styles.overflow, classesItem, {
                  [styles.maxSize]: !isMulti,
                })}
                key={i.id}
                onClick={setAndClose(i.id)}
              >
                <div>{i.name}</div>

                {i.endName && <div>{i.endName}</div>}

                {isMulti && activeIds.includes(i.id) && <div>S</div>}
              </div>
            ))}
          </HOCOutsideClick>
        )}
      </div>
    </div>
  );
};

export default Selector;
