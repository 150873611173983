import React, { useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import TableHeader from './components/TableHeader';
import TableBody from './components/TableBody';
import Pagination from '../Pagination';

import { PAGINATION_RANGE } from './constants';
import { RowInfo, prepareHeaderTabs } from './utils';

import styles from './Table.module.css';

type Props = {
  rows: RowInfo[];
  EditForm?: React.FC<{
    // TODO: GENERIC
    info: RowInfo & { [key: string]: any };
    onClose: () => void;
  }>;
};

const TableUi = ({ rows, EditForm }: Props) => {
  const [items, setItems] = useState<RowInfo[]>([]);

  const [page, setPage] = useState(0);

  const [editFormInfo, setEditFormInfo] = useState<RowInfo | null>(null);

  const onClose = () => {
    setEditFormInfo(null);
  };

  const handleChangePage = useCallback(
    (value: number) => () => {
      const newPage = page + value;

      if (newPage < 0 || newPage > Math.ceil(rows.length / PAGINATION_RANGE)) {
        return;
      }

      setPage(newPage);
    },
    [rows, page]
  );

  const runToPage = useCallback(
    (value: number) => () => {
      const newPage = value - 1;

      setPage(newPage);
    },
    []
  );

  const handleEditAction = (r: RowInfo) => () => {
    setEditFormInfo(r);
  };

  useEffect(() => {
    const start = page * PAGINATION_RANGE;

    const end = start + PAGINATION_RANGE;

    const items = rows.sort((r, r2) => +r.id - +r2.id).slice(start, end);

    setItems(items);
  }, [rows, page]);

  if (!rows.length) {
    return <div>Empty rows</div>;
  }

  return (
    <div className={styles.tableWrapper}>
      <TableHeader
        data={prepareHeaderTabs(rows[0])}
        isEditedAction={Boolean(EditForm)}
      />

      <TableBody
        rows={items}
        handleEditAction={EditForm ? handleEditAction : undefined}
      />

      <Pagination
        currentPage={page}
        items={rows.length}
        handleNextPage={handleChangePage(1)}
        handlePrevPage={handleChangePage(-1)}
        runToPage={runToPage}
      />

      {editFormInfo &&
        createPortal(
          <div className={styles.bg}>
            {EditForm && <EditForm info={editFormInfo} onClose={onClose} />}
          </div>,
          document.body
        )}
    </div>
  );
};

export default TableUi;
