import React, { useEffect, useState } from 'react';
import { Calendar } from 'react-calendar';
import cln from 'classnames';

import styles from './CalendarAndTime.module.css';

import { isToday, prepareDate } from 'utils/date';
import { useZustandNotificationStore } from 'stores/notification/store';
import { addNotificationAction } from 'stores/notification/selectors';
import { ENotificationType } from 'stores/notification/types';
import { getRandomInt } from 'utils/random';

type Props = {
  classes?: string;
  onChange: (d: string) => void;
};
type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

const CalendarAndTime = ({ classes, onChange }: Props) => {
  const [date, setDate] = useState<string>('');

  const addNotification = useZustandNotificationStore(addNotificationAction);

  const handleCalendarInfoChange = (date: Value) => {
    if (!date) {
      console.info('RESET');

      return;
    }

    if (Array.isArray(date)) {
      console.info('range');

      return;
    }

    const getRandomHour = getRandomInt(1, 23);

    date.setHours(getRandomHour);
    const calendarTimestamp = date.getTime();

    if (isToday(date)) {
      addNotification({
        type: ENotificationType.Error,
        uid: 'date',
        autoClose: true,
        text: 'today can not be selected',
      });

      return;
    }

    setDate(prepareDate(calendarTimestamp));
  };

  useEffect(() => {
    if (!date) {
      return;
    }

    onChange(date);
  }, [date]);

  return (
    <div className={cln(styles.wrapper, classes)}>
      <Calendar
        onChange={handleCalendarInfoChange}
        className={styles.input}
        minDate={new Date()}
      />

      {/* TODO add custom time ? */}
    </div>
  );
};

export default CalendarAndTime;
