import { ERouteNames } from '../../Routes/types';

import { ReactComponent as DashboardSVG } from 'assets/csa-icons/drawer/dashboard.svg';
import { ReactComponent as DepositSvg } from 'assets/csa-icons/drawer/deposit.svg';
import { ReactComponent as UsersSVG } from 'assets/csa-icons/drawer/user.svg';

export const USER_PAGES = [
  {
    name: 'Users',
    leftPathName: <UsersSVG />,
    pathname: ERouteNames.Users,
  },
  {
    name: 'Documents',
    leftPathName: <DepositSvg />,
    pathname: ERouteNames.Documents,
  },
  {
    name: 'Pushes',
    leftPathName: <DashboardSVG />,
    pathname: ERouteNames.PushService,
  },
];
