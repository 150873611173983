export interface ZustandNotificationStore extends ZustandNotificationEntities {
  addNotification: (data: Notification) => void;

  removeNotification: (key: string | number) => void;
}

interface ZustandNotificationEntities {
  notifications: Notifications;
}

export type Notifications = {
  [notificationId: string]: Notification;
};

export type Notification = {
  text: string;
  type: ENotificationType;
  uid: string | number;
  autoClose: boolean;
};

export const enum ENotificationType {
  Default,
  Warning,
  Success,
  Error,
}
