import React from 'react';

import ButtonBase from 'components/ui/Buttons/BaseButton';

import styles from './PopUp.module.css';

type Props = {
  children: React.ReactElement;
  title: string;
  handleSubmit?: () => void;
  isValidForm?: boolean; // todo remove as optional props
  onClose: () => void;
};

const PopUp = (props: Props) => {
  const { title, handleSubmit, children, onClose, isValidForm = true } = props;

  return (
    <div className={styles.wrapperPopUp}>
      <div className={styles.title}>{title}</div>
      <div className={styles.closeSvg} onClick={onClose}>
        <img src="/csa-icons/base/close.svg" />
      </div>

      <div className={styles.content}>{children}</div>

      {handleSubmit && (
        <ButtonBase
          text="Submit"
          size="--button-xxl"
          color="red"
          handleClick={handleSubmit}
          buttonHeight="--button-height-l"
          disabled={!isValidForm}
          centreText
        />
      )}
    </div>
  );
};

export default PopUp;
