import { useCallback, useEffect, useState } from 'react';

import BaseInput from 'components/ui/Inputs/BaseInput';
import { InputDefaultState } from 'components/ui/Inputs/BaseInput/types';
import AwsS3FileUpload from './components/AwsS3FileUpload';
import Checkbox from 'components/ui/Checkbox';

import { ReturnAdaptedType, Scheme, adaptedSchemeToState } from 'utils/scheme';

import styles from './Form.module.css';
import CalendarAndTime from './components/CalendarAndTime/CalendarAndTime';

type Props = {
  scheme: Scheme[];
  callback: (data: ReturnAdaptedType) => void;
  defaultValues?: { [key: string]: any };
};

const Form = ({ scheme, callback, defaultValues }: Props) => {
  const [fields, setFields] = useState<ReturnAdaptedType>({});

  const setFieldInfo = (name: string) => (inputInfo: InputDefaultState) => {
    const newState = { ...fields };

    newState[name] = {
      ...newState[name],
      ...inputInfo,
    };

    setFields(newState);
  };

  const handleCalendarInfoChange = (name: string) => (date: string) => {
    const newState = { ...fields };

    newState[name] = {
      ...newState[name],
      value: date,
    };

    setFields(newState);
  };

  const handleChangeState = (name: string) => (state: boolean) => {
    const newState = { ...fields };

    newState[name] = {
      ...newState[name],
      value: state,
    };

    setFields(newState);
  };

  const setInitialFields = useCallback(() => {
    const fields = adaptedSchemeToState(scheme);

    if (!defaultValues) {
      setFields(fields);

      return;
    }

    Object.values(fields).forEach((f) => {
      const key = f.requestFieldName;

      const value =
        key in defaultValues
          ? defaultValues[key]
          : defaultValues?.data?.[f.requestFieldName];

      if (value) {
        f.value = value;

        if (f.convertType === 'boolean') {
          f.value =
            typeof value === 'string' && value.length
              ? value === 'true'
              : Boolean(value);
        }
      }

      return true;
    });

    setFields(fields);
  }, [defaultValues, scheme]);

  useEffect(() => {
    callback(fields);
  }, [fields]);

  useEffect(() => {
    setInitialFields();
  }, [setInitialFields]);

  return (
    <>
      {Object.values(fields).map((field) => {
        if (field.isCheckbox) {
          return (
            <Checkbox
              key={field.name}
              text={field.name}
              isActive={Boolean(fields[field.name].value) as boolean}
              onChange={handleChangeState(field.name)}
              classes={styles.input}
            />
          );
        }

        if (field.isCalendar) {
          return (
            <CalendarAndTime
              key={field.name}
              onChange={handleCalendarInfoChange(field.name)}
              classes={styles.input}
            />
            // <Calendar
            //   key={field.name}
            //   onChange={handleCalendarInfoChange(field.name)}
            //   className={styles.input}
            // />
          );
        }

        if (field.isAwsFile) {
          return (
            <AwsS3FileUpload
              key={field.name}
              onChange={setFieldInfo(field.name)}
              classes={styles.input}
              field={field}
            />
          );
        }

        return (
          <BaseInput
            size="--input-full"
            marginB="base"
            key={field.name}
            placeholder={field.name}
            onChange={setFieldInfo(field.name)}
            inputState={field}
            classes={styles.input}
          />
        );
      })}
    </>
  );
};

export default Form;
