import React, { useRef, useState } from 'react';
import cln from 'classnames';

import { BaseInputProps } from './types';

import styles from './BaseInput.module.css';

const BaseInput = (props: BaseInputProps) => {
  const {
    startIcon,
    endIcon,
    placeholder,
    inputState,
    onChange,
    validationFn,
    marginB,
    classes,
    disabled,
  } = props;

  const [autoFocus, setAutoFocus] = useState(false);

  const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = target;

    const validatedValue = validationFn?.(value) || { value, errorText: '' };

    onChange(validatedValue);
  };

  const handleBlur = () => {
    setAutoFocus(false);
  };

  const handleSetInputFocus = () => {
    setAutoFocus(true);
  };

  const marginBKet = `margin--indent-${marginB}`;

  return (
    <div
      className={cln(styles.wrapperBaseInputContainer, classes, {
        [styles.inputWithValue]: inputState.value,
        [styles[marginBKet]]: marginB,
      })}
      onClick={handleSetInputFocus}
    >
      {startIcon && <div className={styles.startIcoWrapper}>{startIcon}</div>}

      <input
        // TODO boolean for checkbox
        value={inputState.value.toString()}
        autoFocus={autoFocus} //TODO not working
        onChange={handleChange}
        placeholder={placeholder}
        onBlur={handleBlur}
        className={cln(styles.input, {
          [styles.marginL]: startIcon,
          [styles.disabled]: disabled,
        })}
      />

      <div
        className={cln(styles.placeholder, {
          [styles.leftModifier]: startIcon,
          [styles.sizeModifier]: inputState.value,
        })}
      >
        {placeholder}
      </div>

      {endIcon && <div>{endIcon}</div>}
    </div>
  );
};

export default BaseInput;
