import { useEffect, useRef, useState } from 'react';
import cln from 'classnames';

import { ReactComponent as CloseSvg } from 'assets/csa-icons/base/close.svg';
import { NotificationTypeIcon } from '../NotificationTypeIcon';

import { ENotificationType, Notification } from 'stores/notification/types';
import { removeNotificationAction } from 'stores/notification/selectors';
import { useZustandNotificationStore } from 'stores/notification/store';

import styles from './NotificationView.module.css';

const NotificationView = (props: Notification) => {
  const { text, type, uid, autoClose } = props;

  const removeNotification = useZustandNotificationStore(
    removeNotificationAction
  );

  const [isHide, setHideState] = useState<boolean>(false);

  const handleRemoveNotification = () => {
    removeNotification(uid);
  };

  const dismissNotification = () => {
    setHideState(true);
  };

  useEffect(() => {
    if (!autoClose) {
      return;
    }
    const dismissTimer = setTimeout(dismissNotification, 4000);

    return () => {
      clearTimeout(dismissTimer);
    };
  }, [autoClose]);

  return (
    <div
      className={cln(styles.wrapperNotification, {
        [styles.animationMoveToLeftNotification]: !isHide,
        [styles.animationMoveToRightNotification]: isHide,
        [styles.warning]: type === ENotificationType.Warning,
        [styles.error]: type === ENotificationType.Error,
        [styles.success]: type === ENotificationType.Success,
      })}
    >
      <div className={styles.row}>
        <NotificationTypeIcon type={type} />

        <div className={styles.text}>{text}</div>
      </div>

      <CloseSvg
        className={styles.closeIcon}
        onClick={handleRemoveNotification}
      />
    </div>
  );
};

export default NotificationView;
