import { EEventType } from 'components/hocs/HOCOutsideClick/types';
import { useEffect, RefObject } from 'react';

type Event = MouseEvent | TouchEvent;

const useOnClickOutside = <T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: (event: Event) => void,
  eventType: EEventType
) => {
  useEffect(() => {
    const listener = (event: Event) => {
      const el = ref?.current;

      if (!el || el.contains((event?.target as Node) || null)) {
        return;
      }

      event.stopPropagation();
      event.preventDefault();

      handler?.(event); // Call the handler only if the click is outside of the element passed.
    };

    document.addEventListener(eventType, listener);

    return () => {
      document.removeEventListener(eventType, listener);
    };
  }, [ref, handler, eventType]); // Reload only if ref or handler changes
};

export default useOnClickOutside;
