import graphicSide from 'assets/csa-icons/signIn/graphicSide.png';

import SignInForm from 'components/Forms/SignIn';

import { ReturnAdaptedType, getBody } from 'utils/scheme';

import styles from './SignIn.module.css';
import { api } from 'services/api';
import { useZustandNotificationStore } from 'stores/notification/store';
import { addNotificationAction } from 'stores/notification/selectors';
import { ENotificationType } from 'stores/notification/types';

const SignIn = () => {
  const addNotification = useZustandNotificationStore(addNotificationAction);

  const callback = async (data: ReturnAdaptedType) => {
    const body = getBody(data);

    // TODO move to constants pathname
    const response = await api.post('/auth/authorization', body);

    if (!response) {
      addNotification({
        text: 'error',
        type: ENotificationType.Error,
        uid: 'sign-in',
        autoClose: true,
      });

      return;
    }
  };

  return (
    <div className={styles.wrapperSignIn}>
      <div className={styles.signInContent}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>Sign In</div>
          <div className={styles.subtitle}>Sign in to stay connected.</div>
        </div>

        <SignInForm callback={callback} />
      </div>

      <img src={graphicSide} alt="" className={styles.graphicSideSVG} />
    </div>
  );
};

export default SignIn;
