export const SIGN_IN_SCHEME = [
  {
    name: 'Email',
    validationFn: null,
    isRequired: true,
    requestFieldName: 'email',
  },
  {
    name: 'Password',
    validationFn: null,
    isRequired: true,
    requestFieldName: 'password',
  },
];
