import React from 'react';
import cln from 'classnames';
import styles from './Checkbox.module.css';

type Props = {
  isActive: boolean;
  onChange: (s: boolean) => void;
  text?: string;
  classes?: string;
};

const Checkbox = (props: Props) => {
  const { isActive, onChange, text, classes } = props;

  const toggleState = () => {
    onChange(!isActive);
  };

  return (
    <div className={cln(styles.wrapperCheckbox, classes)} onClick={toggleState}>
      <div className={styles.box}>
        {isActive && (
          <img
            className={styles.active}
            src="/csa-icons/base/gui-check-yes-svgrepo-com.svg"
          />
        )}
      </div>

      {text && <div className={styles.name}>{text}</div>}
    </div>
  );
};

export default Checkbox;
