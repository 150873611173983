import cln from 'classnames';

import ItemRow from './components/ItemRow';

import { USER_PAGES } from './constants';

import styles from './Drawer.module.css';

const Drawer = () => (
  <div className={styles.wrapperDrawer}>
    <div className={cln(styles.section, styles.first)}>
      <span className={styles.title}>Users</span>

      {USER_PAGES.map((data, idx) => (
        <ItemRow {...data} key={idx} />
      ))}
    </div>
  </div>
);

export default Drawer;
